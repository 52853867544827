/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Egb9Cipfy",
    "aws_user_pools_web_client_id": "h78cr8r1ehobihhpe2eldmvl2",
    "oauth": {
        "domain": "softwarewolves-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://softwarewolves.eu/login/",
        "redirectSignOut": "https://softwarewolves.eu/,https://softwarewolves.eu/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;

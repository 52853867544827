import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react'
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Are you a software developer? Are you looking for developers for your software project?
        </p>
        <a
          className="App-link"
          href="http://*"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join SoftwareWolves!
        </a>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
